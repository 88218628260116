import { JobPostItem } from '../../../types/JobPost'
import Card from './Card'
import { useContext, useState } from 'react'
import LeadContext from '../../../contexts/LeadContext'
import LeadAcquisitionModal from '../../modals/LeadAcquisitionModal'

interface Props {
  item: JobPostItem
}
export default function JobCard({ item }: Props) {
  const { setLeadFormStaticData } = useContext(LeadContext)
  const [showLeadAcquisition, setShowLeadAcquisition] = useState(false)

  const onApply = (job: JobPostItem) => {
    // just for additional insurance
    if (job.applyURL) {
      window.open(job.applyURL, '_blank')
      return
    }
    setLeadFormStaticData({
      jobPostId: job._id,
      clientId: job.clientId,
      campaignId: job.campaignId || job.defaultCampaign._id,
      clientName: job.client.name,
      heroImage: job.image?.Location || job.client?.resources?.hero?.Location
    })
    setShowLeadAcquisition(true)
  }
  return (
    <>
      <Card
        starBadge={item.campaignPackageWeight >= 0.9}
        className="cursor-pointer  border-transparent border-4 hover:border-[#edbb4c] "
        image={item?.image?.Location || item?.client?.resources?.hero?.Location}
        title={item?.client?.name + ' - ' + item.title}
        phoneNumber={item.trackingNumbers?.[0]?.trackingNumber} // TODO check correctness
        limitOverviewText={200}
        overview={item.overview}
        onApplyNow={() => onApply(item)}
        hideProfile={true}
        href={`/truck-driving-jobs/${item.slug}`}></Card>
      {showLeadAcquisition && (
        <LeadAcquisitionModal
          open={showLeadAcquisition}
          handleClose={setShowLeadAcquisition}
        />
      )}
    </>
  )
}
