import { gql } from '@apollo/client'

export const GET_JOB_POSTS = gql`
  query searchJobPosts($query: JobPostsQuery!) {
    jobPosts(query: $query) {
      items {
        _id
        title
        clientId
        details
        overview
        campaignId
        featured
        applyURL
        trackingNumbers {
          _id
          routingNumber
          trackingNumber
        }
        defaultCampaign {
          title
          _id
        }
        client {
          applyNowTxt
          name
          resources {
            logo {
              Location
            }
            hero {
              Location
            }
          }
        }
        image {
          Location
        }
        campaign {
          title
        }
        createdAt
        isDefault
        campaignPackageWeight
        slug
      }
      totalCount
    }
  }
`

export const GET_JOB_POSTS_BY_ID = gql`
  query GetJobPost($_id: ID!) {
    jobPost(_id: $_id) {
      _id
      slug
    }
  }
`
export const GET_JOB_POSTS_BY_SLUG = gql`
  query GetJobPost($slug: ID!) {
    jobPostBySlug(slug: $slug) {
      _id
      title
      details
      clientId
      campaignId
      featured
      allStatesCoordinates
      includeCompanyInfo
      applyURL
      slug
      hiringArea {
        rules {
          in {
            hasAreaFilter # this field is in use, in order to load all state poligons
            states
            cities {
              city {
                name
                stateCode
                geo {
                  coordinates
                }
              }
              radius
            }
            polygons {
              type
              coordinates
            }
            statesPolygon
            zipCodes {
              radius
              zipCode
              geo {
                type
                coordinates
              }
            }
          }
        }
      }
      client {
        applyNowTxt
        name
        body
        resources {
          hero {
            Location
            Key
          }
          logo {
            Location
            Key
          }
        }
      }
      overview
      campaign {
        title
      }
      defaultCampaign {
        title
        _id
      }
      trackingNumbers {
        routingNumber
        trackingNumber
      }
      hero {
        Location
        Key
      }
      image {
        Location
        Key
      }
      createdAt
      campaignPackageWeight
    }
  }
`
