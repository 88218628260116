import { gql } from '@apollo/client'

export const GET_SEARCH_CONFIG = gql`
  query SearchConfigs($type: ConfigType!, $campaignId: ID) {
    searchConfigs(query: { type: $type, campaignId: $campaignId }) {
      _id
      campaignId
      type
      fields {
        _id
        label
        type
        size
        searchKey
        order
        dictionaryId
        config
        options {
          _id
          title
        }
      }
    }
  }
`
