import { useCallback, useContext, useEffect, useState } from 'react'
import apolloClient from '../../apollo-client'

import ClearButton from '../ui/ClearButton'
import SingleLineMultiSelect from '../ui/SingleLineMultiSelect'
import SuccessButton from '../ui/SuccessButton'
import FilterContainer from './FilterContainer'

import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import { GET_SEARCH_CONFIG } from '../../graphql/searchConfig'
import { DynamicFieldType } from '../../types/LeadForm'
import LocationField from '../ui/LocationField'
import JobsContext from '../../contexts/JobsContext'
import {
  JobfilterFieldQuery,
  JobFilterForm,
  JobLocationQuery,
  JobQuery
} from '../../types/JobPost'
import GlobalContext from '../../contexts/GlobalContext'
import CompanyFilterInput from '../ui/CompanyFilterInput'

type Props = {
  showAsDockedOnMobile?: boolean
  companyFilter?: boolean
}
const JobPostFilters = ({
  showAsDockedOnMobile = true,
  companyFilter = true
}: Props) => {
  const { setMobileFilterActive, setErrorMsg } = useContext(GlobalContext)

  const { setFilters, formFilterValues, setFormFilterValues, stateName } =
    useContext(JobsContext)

  const { control, reset, getValues, setValue } = useForm<JobFilterForm>({
    defaultValues: formFilterValues
  })

  const [dynamicFilters, setDynamicFilters] = useState<any[]>([])

  const loadFilters = useCallback(async () => {
    try {
      const { data } = await apolloClient.query({
        query: GET_SEARCH_CONFIG,
        variables: {
          type: 'jobPost'
        }
      })

      const cfgs = data.searchConfigs
      if (cfgs?.length) {
        const { fields } = cfgs[0]

        setDynamicFilters(fields)
      }
    } catch (e) {
      setErrorMsg('Uh-oh! Error loading filter. Try again soon.')
    }
  }, [setErrorMsg])

  useEffect(() => {
    loadFilters()
  }, [loadFilters])

  const onSubmit = () => {
    const values = getValues()
    const { dynamicFormValues, location, client } = values
    setFormFilterValues(values)

    let filterFields: JobfilterFieldQuery[] = dynamicFilters.map((f: any) => ({
      value: dynamicFormValues[f._id],
      type: f.type,
      searchKey: f.searchKey,
      config: f.config
    }))

    filterFields = filterFields.filter((f) => {
      if (Array.isArray(f.value) && !f.value.length) {
        return false
      }
      return !!f.value
    })

    const locFilter: Partial<JobLocationQuery> = {}
    if (location) {
      switch (location.type) {
        case 'state': {
          locFilter.states = [location.value]
          break
        }
        case 'city': {
          locFilter.city = {
            cityId: location.value,
            radius: 50
          }
          break
        }
        case 'zipCode': {
          locFilter.zip = {
            zipCode: location.value,
            radius: 50
          }
        }
      }
    }

    const filterQuery: Partial<JobQuery> = {
      filterFields,
      location: locFilter,
      clientId: client?._id
    }

    setFilters(filterQuery)
    setMobileFilterActive()
  }

  useEffect(() => {
    if (stateName && formFilterValues.location) {
      setValue('location', formFilterValues.location)
    }
  }, [stateName, formFilterValues.location, setValue])

  return (
    <FilterContainer showAsDockedOnMobile={showAsDockedOnMobile}>
      <form
        className="w-full"
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}>
        <Grid
          spacing={0.5}
          container
          sx={{
            px: { lg: 0, xs: 1 }
          }}
          direction="row"
          justifyContent="end"
          alignItems="stretch">
          <Grid xs item>
            <LocationField controlName="location" control={control} />
          </Grid>

          {dynamicFilters.map((f) => {
            if (f.type === DynamicFieldType.SELECT && f.config.multi) {
              return (
                <Grid item xs={12} sm={6} lg={2} xl={2} md={2} key={f._id}>
                  <SingleLineMultiSelect
                    control={control}
                    controlName={`dynamicFormValues.${f._id}`}
                    placeholder={f.label}
                    fieldNames={{
                      fieldLabel: 'title',
                      fieldValue: '_id'
                    }}
                    staticData={f.options}
                  />
                </Grid>
              )
            }
          })}

          {companyFilter && (
            <Grid item xs={12} sm={12} lg={2} xl={2} md={2}>
              <CompanyFilterInput control={control}></CompanyFilterInput>
            </Grid>
          )}

          <Grid item xs={12} sm={6} lg={2} md={2}>
            <ClearButton
              className="mr-1 h-full"
              onClick={() => {
                const fValues = formFilterValues.dynamicFormValues || {}

                for (const fkey in fValues) {
                  fValues[fkey] = []
                }

                setFilters({})
                setFormFilterValues({})

                reset({
                  dynamicFormValues: fValues,
                  location: {}
                })
                setMobileFilterActive()
              }}>
              Clear
            </ClearButton>
          </Grid>

          <Grid item xs={12} sm={6} lg={2} md={2}>
            <SuccessButton onClick={onSubmit}>SEARCH</SuccessButton>
          </Grid>
        </Grid>
      </form>
    </FilterContainer>
  )
}

export default JobPostFilters
