import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import apolloClient from '../../apollo-client'
import { shuffleArray } from '../../lib/Utils'
import { LoadMask } from '../../components/ui/LoadMask'
import { GET_JOB_POSTS } from '../../graphql/jobPost'
import { JobPostItem, JobQuery } from '../../types/JobPost'
import NoResult from '../ui/NoResult'
import JobPostFilters from '../filters/JobPostFilters'
import JobsContext from '../../contexts/JobsContext'
import JobCard from '../ui/card/JobCard'
import GlobalContext from '../../contexts/GlobalContext'
interface Props {
  clientId?: string
  excludeJobPostId?: string
}
const LIMIT = 9

export default function JobsSearch({ clientId, excludeJobPostId }: Props) {
  const [topJobPost, setTopJobPost] = useState<JobPostItem[]>([])
  const { setErrorMsg } = useContext(GlobalContext)
  const [pinnedJobPost, setPinnedJobPost] = useState<JobPostItem[]>([])
  const [jobs, setJobs] = useState<JobPostItem[]>([])
  const [loading, setLoading] = useState(false)
  const allJobsAreLoading = useRef(false)
  const [totalCnt, setTotalCnt] = useState(0)
  const baseStaticFilters = useRef({
    active: true,
    isPublished: true,
    clientId,
    topTen: false
  })

  const excludeJobPostIds = useRef(excludeJobPostId ? [excludeJobPostId] : [])

  const { filters } = useContext(JobsContext)

  const pageSize = useRef(0)

  const loadTopJobPosts = useCallback(
    async (filters: Partial<JobQuery>) => {
      try {
        const query: any = {
          ...filters,
          excludeJobPostIds: excludeJobPostIds.current,

          topJobPost: 6,
          sort: {
            active: 'campaignPackageWeight',
            direction: 'desc'
          },
          ...baseStaticFilters.current,
          clientId: filters.clientId || baseStaticFilters.current.clientId
        }

        if (!baseStaticFilters.current.clientId) {
          query.feed = false
        }

        const { data } = await apolloClient.query({
          query: GET_JOB_POSTS,
          variables: {
            query
          }
        })

        const jobs = data.jobPosts.items as JobPostItem[]

        setTopJobPost(jobs)
        excludeJobPostIds.current = excludeJobPostIds.current.concat(
          ...jobs.map((j) => j._id)
        )
      } catch (e) {
        setErrorMsg('Uh-oh! Error loading truck driving jobs. Try again soon.')
      }
    },
    [setErrorMsg]
  )

  const loadPinnedJobPost = useCallback(
    async (filters: Partial<JobQuery>) => {
      excludeJobPostIds.current = excludeJobPostId ? [excludeJobPostId] : []

      try {
        const { data } = await apolloClient.query({
          query: GET_JOB_POSTS,
          variables: {
            query: {
              ...filters,
              pinned: true,

              sort: {
                active: 'campaignPackageWeight',
                direction: 'desc'
              },
              ...baseStaticFilters.current,
              clientId: filters.clientId || baseStaticFilters.current.clientId
            }
          }
        })

        const jobs = data.jobPosts.items as JobPostItem[]

        setPinnedJobPost(jobs)
        excludeJobPostIds.current = excludeJobPostIds.current.concat(
          ...jobs.map((j) => j._id)
        )
      } catch (e) {
        setErrorMsg('Uh-oh! Error loading truck driving jobs. Try again soon.')
      }
    },
    [excludeJobPostId, setErrorMsg]
  )

  const loadRegularJobs = useCallback(
    async (filters: Partial<JobQuery>, reset = false) => {
      try {
        if (reset) {
          setTotalCnt(0)
          setJobs([])
          pageSize.current = 0
        }

        const query: any = {
          ...filters,
          excludeJobPostIds: excludeJobPostIds.current,

          isDefault: false,
          skip: pageSize.current,
          limit: LIMIT,
          sort: {
            active: 'campaignPackageWeight',
            direction: 'desc'
          },
          ...baseStaticFilters.current,
          clientId: filters.clientId || baseStaticFilters.current.clientId
        }

        if (!baseStaticFilters.current.clientId) {
          query.feed = false
        }

        const { data } = await apolloClient.query({
          query: GET_JOB_POSTS,
          variables: {
            query
          }
        })
        const jobItems = data.jobPosts.items as JobPostItem[]
        const mixedJobs = shuffleArray(
          jobItems.filter((item) => item.campaignPackageWeight >= 0.9)
        ).concat(
          shuffleArray(
            jobItems.filter(
              (item) =>
                !item.campaignPackageWeight || item.campaignPackageWeight < 0.9
            )
          )
        )

        setJobs((oldArray) => [...oldArray, ...mixedJobs])

        setTotalCnt(data.jobPosts.totalCount)

        pageSize.current = pageSize.current + LIMIT
      } catch (e) {
        setErrorMsg('Uh-oh! Error loading truck driving jobs. Try again soon.')
      }
    },
    [setErrorMsg]
  )

  const load = useCallback(async () => {
    if (allJobsAreLoading.current) {
      return
    }
    allJobsAreLoading.current = true
    setLoading(true)

    pageSize.current = 0

    await loadPinnedJobPost(filters)
    await loadTopJobPosts(filters)
    await loadRegularJobs(filters, true)
    setLoading(false)
    allJobsAreLoading.current = false
  }, [loadRegularJobs, loadTopJobPosts, loadPinnedJobPost, filters])

  useEffect(() => {
    load()
  }, [load])

  const allJobs = pinnedJobPost.concat(topJobPost).concat(jobs)
  return (
    <>
      <JobPostFilters
        companyFilter={!clientId}
        showAsDockedOnMobile={!clientId}
      />

      <InfiniteScroll
        scrollableTarget="scrollableTarget"
        dataLength={jobs.length}
        loader={null}
        style={{ position: 'relative' }}
        next={() => {
          loadRegularJobs(filters, false)
        }}
        hasMore={totalCnt > pageSize.current}>
        <LoadMask
          className={clientId ? 'absolute' : undefined}
          active={loading}></LoadMask>

        <div className="max-w-[1200px] mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
          {allJobs.map((item, i) => {
            return <JobCard item={item} key={i}></JobCard>
          })}
        </div>
        <NoResult visible={!loading && !allJobs.length} />
      </InfiniteScroll>
    </>
  )
}
