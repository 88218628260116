import { useCallback, useEffect, useState } from 'react'

import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { LocationRecord } from '../../types/Common'
import {
  formatState,
  searchCities,
  searchStates,
  validateZip
} from '../../lib/Utils'
import useDebounce from '../../hooks/useDebounce'
interface Props {
  controlName: string
  control: Control<any, any>
}

const LocationField = React.forwardRef(
  ({ control, controlName }: Props, ref) => {
    const [locSuggestions, setLocSuggestions] = useState<LocationRecord[]>([])

    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const searchDebounce = useDebounce(searchValue, 250)

    const getLocationSuggestions = useCallback(async (input: string) => {
      if (!input) {
        setLocSuggestions([])
        return
      }

      setLoading(true)
      if (!isNaN(+input) && input.length === 5) {
        const isValidZip = await validateZip(input)

        isValidZip &&
          setLocSuggestions([
            {
              type: 'zipCode',
              label: input,
              value: input
            }
          ])
      } else {
        const cities = await searchCities(input)

        const states = searchStates(input)

        const suggestions = states.map(formatState).concat(
          cities.map((c) => ({
            type: 'city',
            label: `${c.name} (${c.stateCode})`,
            value: c._id
          }))
        )
        setLocSuggestions(suggestions as LocationRecord[])
      }
      setLoading(false)
    }, [])

    useEffect(() => {
      getLocationSuggestions(searchDebounce)
    }, [searchDebounce, getLocationSuggestions])

    return (
      <Controller
        name={controlName}
        defaultValue={null}
        render={({ field }) => (
          <Autocomplete
            {...field}
            ref={ref}
            loading={loading}
            open={open}
            clearOnBlur={false}
            value={field.value}
            onClose={() => {
              setOpen(false)
            }}
            onOpen={() => {
              if (searchValue || field.value) {
                setOpen(true)
              }
            }}
            noOptionsText="No Result"
            onChange={(_, data) => {
              field.onChange(data)
            }}
            onInputChange={(_, value) => {
              if (field.value) {
                setLocSuggestions([field.value])
              } else {
                // in order to fix bounce delay
                if (value) {
                  setLoading(true)
                } else {
                  setOpen(false)
                }

                setSearchValue(value)
              }
            }}
            size="small"
            isOptionEqualToValue={(option, value) => option._id === value._id}
            classes={{
              root: 'bg-white',
              inputRoot: 'rounded-none'
            }}
            options={locSuggestions}
            groupBy={(option) => option.type}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField
                placeholder="Location (city, state or zip)"
                variant="outlined"
                {...params}
                InputProps={{
                  ...params.InputProps,

                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        )}
        control={control}></Controller>
    )
  }
)

LocationField.displayName = 'LocationField'
export default LocationField
